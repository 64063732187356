import React from "react";
import ContactForm from "../components/sections/ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Three Brains | Contact Us</title>
        <meta
            name="description"
            content="If you have any questions or require assistance, please feel free
            to reach out to our Sales Support Team"
        />
        <link rel="canonical" href="https://threebrains.com.uy/contact-us" />
      </Helmet>
      <ContactForm className="illustration-section-01" />
    </>
  );
};

export default Contact;
