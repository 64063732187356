import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  

  return (
    <section {...props} className={outerClasses}>
      <script type="application/ld+json">
          {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Corporation",
          "name": "Three Brains |Experts in Salesforce Development",
          "alternateName": "threebrains",
          "description":"Elevate your business with our Salesforce expertise. Achieve simple, beautiful, and powerful results that drive your business forward",
          "url": "https://threebrains.com.uy/",
          "logo": "https://media.licdn.com/dms/image/D4D03AQHfDIWpSyOIRg/profile-displayphoto-shrink_200_200/0/1667835596053?e=1728518400&v=beta&t=n9dagdaTl_XCMkcuiVwcAioLpjTWw8a7zg_cCLwDyJQ",
          "sameAs": "https://www.linkedin.com/in/three-brains-a96437218/"
        })}
      </script>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content m">
            <h1 className="mt-0">Three Brains</h1>

            <h2
              style={{ fontSize: "2.1rem" }}
              className="mt-5 mb-16 reveal-from-bottom typing-effect  "
              data-reveal-delay="200"
            >
              Your Experts in   <span className="text-color-primary ">
              Salesforce Development
              </span>
             
            </h2>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Take your business to the next level, with our expertise get
                simple, beautiful and powerful results.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Link to="/contact-us">
                    <Button
                      
                      color="primary"
                      className="text-white"
                      wideMobile
                    >
                      Contact Us
                    </Button>
                  </Link>
                  <Link to="/works">
                    <Button  color="dark" wideMobile>
                      About us
                    </Button>
                  </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
