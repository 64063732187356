import { Alert, CircularProgress, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import Button from "../elements/Button";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Buttonc from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

import SendIcon from "@mui/icons-material/Send";

import './tailwind.min.css';


//

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const card = (title, description) => (
  <React.Fragment>
    <CardContent>
      <Typography variant="h5" component="div">
        {title == "WhatsApp" && <WhatsAppIcon />}
        {title == "Email" && <EmailIcon />}
      </Typography>
      <Typography variant="body2">{description}</Typography>
      <br />

      {title == "WhatsApp" && (
        <a
          href="https://wa.me/+59894345946?text=I want to know more about your sevices"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Buttonc variant="contained" size="small" endIcon={<SendIcon />}>
            Send
          </Buttonc>
        </a>
      )}
      {title == "Email" && (
        <a
          href="mailto:contact@threebrains.com.uy?subject=Contact to ThreeBrains Team"
          rel="noopener noreferrer"
        >
          <Buttonc
            variant="contained"
            size="small"
            className="ml-auto mr-auto"
            endIcon={<EmailIcon />}
          >
            Send
          </Buttonc>
        </a>
      )}
    </CardContent>
  </React.Fragment>
);

const ContactForm = () => {
  const [capVal, setCapVal] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target?.email.value && e.target?.message.value) {
      window.emailjs.sendForm("service_yrn2t9f", "template_e6yrp2a", e.target);
      setHasError(false);
 
      setRefreshForm(true);
      setTimeout(() => {
        setRefreshForm(false);
        setHasSuccess(true);
   
      }, 3500);
    } else {
      setHasError(true);
    }
  };

  return (
    <section
      className="hero section center-content  "
      style={{ marginTop: "3%" }}
    >
      <div class=" pb-12">
        <div class="isolate bg-white px-6 py-24 sm:py-10 lg:px-8">
          <div class="mx-auto max-w-2xl text-center">
            <h1 class="text-3xl ">We are here to help!</h1>
            <p class="mt-2 ">
              If you have any questions or require assistance, please feel free
              to reach out to our Sales Support Team
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            method="POST"
            class="mx-auto mt-16 max-w-xl sm:mt-20"
          >
            {hasSuccess && (
              <Alert variant="filled" className="mt-3 mb-3" severity="success">
                Success ! We will get in touch as soon as possible
              </Alert>
            )}
            {!refreshForm && (<>
              <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    for="first-name"
                    class="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div class="mt-2.5">
                    <input
                      type="text"
                      name="first_name"
                      id="first-name"
                      autocomplete="given-name"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="last-name"
                    class="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div class="mt-2.5">
                    <input
                      type="text"
                      name="last_name"
                      id="last-name"
                      autocomplete="family-name"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="company"
                    class="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Company
                  </label>
                  <div class="mt-2.5">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autocomplete="organization"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="email"
                    class="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div class="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autocomplete="email"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label
                    for="message"
                    class="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Message
                  </label>
                  <div class="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows="4"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                </div>

                <ReCAPTCHA
                  sitekey="6Lf4qm4pAAAAAB22B3rYCSqiQhP1uk3SzvfOxHrf"
                  onChange={(val) => setCapVal(val)}
                />
              </div>
               <div class="mt-10">
               <button
                 type="submit"
                 disabled={!capVal}
                 class="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                 Let's talk
               </button>
             </div>
             </>
            )}

            {refreshForm && <>
              <CircularProgress  className="mt-5" size={'4.3rem'}/>
              <p>Processing...</p>
            </>}
           
            {hasError && (
              <Alert variant="filled" className="mt-3" severity="error">
                Complete email and message fields.
              </Alert>
            )}
          
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
