import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function formatDate(dateString) {
  // Parse the date string
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Return in the desired format
  return `${day}/${month}/${year}`;
}

const BlogItem = () => {
  const { blogId } = useParams(); //
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(
      "https://threebrains.com.uy/serverthreebrains/wp-json/wp/v2/posts/" +
        blogId
    )
      .then((response) => response.json())
      .then((data) => {
        setPost(data);
        console.log(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [blogId]);

  return (
    <section className="hero section   w-100 " style={{ marginTop: "7%" }}>
      <div class="bg-white  container">
        {post && (
          <article
            key={post.id}
            class="flex  flex-col items-start justify-between"
          >
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Blog | {post.title.rendered}</title>
                  <meta
                    name="description"
                    content={post.excerpt.rendered}
                  />
                  <link rel="canonical" href={"https://threebrains.com.uy/blog/"+post.id} />
                </Helmet>
            <div class="flex items-center gap-x-4 text-xs" style={{width:'100%'}} >
                <Link to='/blog'>
                    <KeyboardBackspaceIcon />
                </Link>
                <div style={{display:'block',marginLeft:'auto'}}>
                <time datetime="2020-03-16" class="text-gray-500 ">
                {formatDate(post.modified)}
              </time>
              <a
                href="#"
                class="relative  rounded-full bg-blue-400 px-3 py-1.5 font-medium text-white hover:bg-gray-100 ml-5"
              >
                SALES
              </a>
                </div>
            
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <span class="absolute inset-0"></span>
                <div
                  dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                />
              </h3>
              <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.content.rendered
                  }}
                />
              </p>
            </div>
         
          </article>
        )}
      </div>
    </section>
  );
};

export default BlogItem;
