import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { Avatar } from "@mui/material";

import logoThree from "../../assets/images/logo.png";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Client testimonials",
    //paragraph: 'Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.'
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>

            <div class="mx-auto max-w-7xl pt-0 py-8 lg:px-8 " style={{display:'none'}}>
              <div class=" mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <img
                  class="col-span-2 max-h-12 w-full object-contain lg:col-span-1 fundacion-logo"
                  src="https://ciudaddelsaber.org/wp-content/uploads/2019/12/cropped-logo_cds-1.png"
                  alt="Transistor"
                  width="158"
                  height="48"
                />
                <img
                  class="col-span-2 max-h-12 w-full object-contain lg:col-span-1 p-2"
                  src="https://bectionary.com/static/media/LOGO%20BECTIONARY%20-%20COLOR2.82c4c8f7.png"
                  alt="Reform"
                  width="158"
                  height="48"
                />
                <img
                  class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="https://programaletras.com/wp-content/uploads/2018/08/hyg-1.png"
                  alt="Tuple"
                  width="158"
                  height="48"
                />
                <img
                  class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1 fundacion-logo p-0"
                  src="https://idecommerce.cl/static/media/Capa%201.70f70336.png"
                  alt="SavvyCal"
                  width="158"
                  height="48"
                />
                <img
                  class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                  src="https://www.maxipublica.com/web/image/website/1/logo/MaxiPublica?unique=d0efcfa"
                  alt="Statamic"
                  width="158"
                  height="48"
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Avatar
                    alt="IdCommerce"
                    style={{ background: "#6163FF" }}
                    src="https://idecommerce.cl/static/media/Capa%201.70f70336.png"
                  />

                  <p className="text-sm mb-0">
                    Three Brains developed our project that only existed as an
                    idea, executing and capturing everything from scratch.
                    Applying the most current programming languages ​​and tools.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    A.S.M
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="https://idecommerce.cl/">ID-ecommerce</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Avatar
                    alt="Bectionary"
                    style={{ background: "white" }}
                    src="https://bectionary.com/static/media/LOGO%20BECTIONARY%20-%20COLOR2.82c4c8f7.png"
                  />
                  <p className="text-sm mb-0">
                    Very good work. Very professional and with creative
                    solutions. Very good communication and availability. Highly
                    recommend working with them
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Bectionary Company
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="https://bectionary.com/">Bectionary</a>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Avatar
                    alt="Threebrains logo"
                    style={{ background: "white" }}
                    src={logoThree}
                  />
                  <p className="text-sm mb-0">
                    Excellent work, very dynamic and fluid, the logo created met
                    all expectations, very empathetic, understanding and the way
                    in which the project was developed was a pleasure. Very good
                    professionals, 1000% recommended
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Salesforce Projects
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">Salesforce</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
