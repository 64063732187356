import React from "react";
import EmailIcon from "@mui/icons-material/Email";


const HeaderInfo = ()=>(
    <div style={{background:'#5658DD'}}>
        <p  className="container font-header">
            <EmailIcon />  <a href='mailto:contact@threebrains.com.uy?subject=Contact to ThreeBrains Team' style={{color:'white'}}  rel="noopener noreferrer">contact@threebrains.com.uy</a>
        </p>
    </div>
);


export default  HeaderInfo;