import React from "react";
import logo from "../assets/images/logo.png";
import "./works.css";
import { Helmet } from "react-helmet";

const Works = () => {
  return (
    <section
      className="hero section  "
      style={{
        paddingLeft: "8%",
        paddingRight: "8%",
        marginTop: "7%",
      }}
    >
        <Helmet>
        <meta charSet="utf-8" />
        <title >Three Brains | About us</title>
        <meta
            name="description"
            content="Learn how to grow your business with our expert advice."
        />
        <link rel="canonical" href="https://threebrains.com.uy/works" />
      </Helmet>
      <div class="bg-white container ">
        <div class="mx-auto  ">
          <div class="mx-auto max-w-2xl lg:mx-0" style={{position:'relative'}}>
            <h1 class="text-3xl font-bold ">
              About us
            </h1>
            <p class="mt-2 text-lg ">
              Learn how to grow your business with our expert advice.
            </p>
            <div class="loader">
              <div class="box box-1">
                <div class="side-left"></div>
                <div class="side-right"></div>
                <div class="side-top"></div>
              </div>
              <div class="box box-2">
                <div class="side-left"></div>
                <div class="side-right"></div>
                <div class="side-top"></div>
              </div>
              <div class="box box-3">
                <div class="side-left"></div>
                <div class="side-right"></div>
                <div class="side-top"></div>
              </div>
              <div class="box box-4">
                <div class="side-left"></div>
                <div class="side-right"></div>
                <div class="side-top"></div>
              </div>
            </div>
          </div>
          <div class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-8 sm:pt-8 lg:mx-0 lg:max-w-none ">
            <div style={{ width:'100%' }}>
              <p>
                Three brains. We are a team of Full Stack - Salesforce
                developers, with 5 years of experience in the area.We work with
                app integrations (Whatsapp, google, etc) in Salesforce and also
                with custom app development with integrations and so on.
                Programming languages ​​(Apex, LWC, Aura component, Node js,
                Omnistudio,Vlocity, SiteGenesis, StoreFront, etc)
                </p>
                <p>
                  Full stack developers, working in the area of ​​Laravel(Php),
                  Node Js, C#, Java, python, React Js, javascript, css,
                  bootstrap, SLDS, community builder, app builder, Responsive
                  Design and others.
                </p>
                <p>
                  We founded ThreeBrains in 2018 and we work for multiple
                  customers
                </p>
              
           
              
            <div class="terminal-loader">
              <div class="terminal-header">
                <div class="terminal-title">Status</div>
                <div class="terminal-controls">
                  <div class="control close"></div>
                  <div class="control minimize"></div>
                  <div class="control maximize"></div>
                </div>
              </div>
              <div class="text">Start...</div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
