import { Box, SpeedDial, SpeedDialIcon } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ChatBot from "react-simple-chatbot";

import './tailwind.min.css';

import { ThemeProvider } from "styled-components";
// all available props
const theme = {
  background: "white",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#5658DD",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#5658DD",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const steps = [
  {
    id: "1",
    message: `👋 Hello there! Welcome to BrainyBot!`,
    trigger: "2",
  },
  {
    id: "2",
    message: `How can I help you today?😊`,
    trigger: "3",
  },
  {
    id: '3',
    options: [
      { value: 1, label: 'Our Services', trigger: '4' },
      { value: 2, label: 'Support', trigger: '5' },
      { value: 3, label: 'Contacts', trigger: '6' },
    ],
  },
  {
    id: "4",
    message: `We working in the area of Salesforce,​​Laravel(Php), Node Js, C#, Java, python, React Js, javascript, css, bootstrap, SLDS, community builder, app builder, Responsive Design and others.`,
    trigger: "9",
  },
  {
    id: "5",
    message: `We are able to offer assistance in various technology-related areas.The FIRST  consultant is FREE.Contact using email in header`,
    trigger: "9",
  },
  {
    id: "6",
    message: `We work in the GMT-3 zone time we can schedule a meeting to check and reviews  your requirements `,
    trigger: "7",
  },
  {
    id: "7",
    message: `Contact us : contact@threebrains.com.uy `,
    trigger: "9",
  },
  {
    id: '9',
    message: `Would you like to contiue search?`,
    trigger: "11",
  },
  {
    id: '11',
    options: [
      { value: 1, label: 'Yes', trigger: '2' },
      { value: 2, label: 'No', trigger: '10' },
    ],
  },
  {
    id: "10",
    message: "I hope it helped you!",
    end: true,
  },
];

const ChatBotCustom = () => {


    const [resetSteps, setResetSteps] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    useEffect(() => {
      // Add an event listener to handle window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  

  return (
    <div className="chatBot">
      <ThemeProvider theme={theme}>
        {!resetSteps && <ChatBot headerTitle="" steps={steps } floating  opened={false}  />}
      </ThemeProvider>
    </div>
  );
};

export default ChatBotCustom;
