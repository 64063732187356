import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function formatDate(dateString) {
  // Parse the date string
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Return in the desired format
  return `${day}/${month}/${year}`;
}

const BlogSection = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://threebrains.com.uy/serverthreebrains/wp-json/wp/v2/posts/")
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading posts: {error.message}</p>;

  return (
    <section className="hero section   " style={{ marginTop: "7%" }}>
      <div class="bg-white  container">
        <div class="mx-auto  ">
          <div class="mx-auto max-w-2xl lg:mx-0">
            <h1 class="text-3xl font-bold ">Blog</h1>
            <p class=" text-lg leading-8 text-gray-600 ">
              Learn how to grow your business with our expert advice.
            </p>
          </div>
          <div class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-8 sm:pt-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <>
                <article
                  key={post.id}
                  class="flex max-w-xl flex-col items-start justify-between"
                >
                  <div class="flex items-center gap-x-4 text-xs">
                    <time datetime="2020-03-16" class="text-gray-500">
                      {formatDate(post.modified)}
                    </time>
                    <a
                      href="#"
                      class="relative  rounded-full bg-blue-400 px-3 py-1.5 font-medium text-white hover:bg-gray-100"
                    >
                      SALES
                    </a>
                  </div>
                  <div class="group relative">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <Link to={"/blog/" + post.id}>
                        <span class="absolute inset-0"></span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: post.title.rendered,
                          }}
                        />
                      </Link>
                    </h3>
                    <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            post.excerpt.rendered.length > 180
                              ? post.excerpt.rendered.substring(0, 180) +
                                " ...."
                              : post.excerpt.rendered.substring(0, 150),
                        }}
                      />
                    </p>
                  </div>
                </article>
              </>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
