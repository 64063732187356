import React from "react";
import { Helmet } from "react-helmet";
import BlogSection from "../components/sections/BlogSection";

const Blog = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Three Brains | Blog</title>
        <meta
            name="description"
            content="Learn how to grow your business with our expert advice."
        />
        <link rel="canonical" href="https://threebrains.com.uy/blog" />
      </Helmet>
      <BlogSection />
    </>
  );
};

export default Blog;
